import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { format } from "date-fns";
import {
  darkBlue,
  lightBlue,
  lightGrey,
  mainWhite,
  transHover,
  Subtitle,
  StyledLink,
  InnerWrapper,
  screen,
} from "../components/common/variables";
import { reduceWords, toSlug } from "../helpers";
import { fadeIn } from "../components/common/animations";
import bgImg from "../images/insights-bg.png";
import { FaChevronRight } from "react-icons/fa";
import { BiSortAlt2 } from "react-icons/bi";

const Wrapper = styled.div`
  padding-top: 0px;
  background-color: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 70px;
  }

  .recent-blog {
    padding-top: 90px;
    padding-bottom: 90px;
    background-image: url(${bgImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: none;
    @media ${screen.medium} {
      display: block;
    }

    .inner-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      margin-bottom: 20px;
      line-height: 1.2;
    }

    &__img {
      width: 495px;
      @media ${screen.large} {
        width: 625px;
      }
    }

    &__video {
      video {
        width: 495px;
        @media ${screen.large} {
          width: 625px;
        }
      }
    }

    &__texts {
      max-width: 610px;
      align-self: center;
      margin-left: 30px;
    }

    &__categories {
      color: ${darkBlue};
      font-size: 1.1rem;
      margin-bottom: 20px;
    }

    &__description {
      color: ${darkBlue};
      font-size: 1.1rem;
      margin-bottom: 36px;
    }
  }

  .filter-control {
    background: ${darkBlue};

    &__title {
      color: ${mainWhite};
      user-select: none;
      font-weight: 700;
      font-size: 1.3rem;
      padding-top: 18px;
      padding-bottom: 18px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.medium} {
        font-size: 1.7rem;
        padding-top: 25px;
        padding-bottom: 25px;
      }
      &:hover {
        color: ${lightBlue};
      }
      span {
        margin-left: 5px;
        display: inline-block;
        @media ${screen.medium} {
          margin-left: 9px;
          margin-top: -3px;
          vertical-align: middle;
        }
      }
    }

    &__list {
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      flex-direction: column;
      max-height: 0;
      overflow: hidden;
      transition: all 0.25s ease-out;
      @media ${screen.small} {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &--is-open {
        max-height: ${(props) => `${props.categoriesMobileHeight}px`};
        @media ${screen.small} {
          max-height: ${(props) => `${props.categoriesDesktopHeight + 50}px`};
        }
      }

      li {
        color: ${mainWhite};
        cursor: pointer;
        transition: ${transHover};
        font-size: 1rem;
        margin-right: 50px;
        margin-bottom: 18px;
        padding: 0 3px 3px 3px;
        @media ${screen.medium} {
          font-size: 1.1rem;
          margin-bottom: 25px;
        }
        &:hover {
          color: ${lightBlue} !important;
          box-shadow: 0 4px 0 -2.7px ${lightBlue} !important;
        }
      }
    }
  }

  .blogs-container {
    margin-top: 30px;
    padding-bottom: 45px;
    @media ${screen.medium} {
      margin-top: 60px;
      padding-bottom: 90px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0 0 0;
      @media ${screen.xSmall} {
        margin: 40px -22px 0 -22px;
      }

      article {
        animation: ${fadeIn} 0.38s ease-out;
        width: 100%;
        margin: 0 0 50px 0;
        @media ${screen.xSmall} {
          margin: 0 22px 60px 22px;
          width: calc(50% - 44px);
        }
        @media ${screen.medium} {
          width: calc(33.33% - 44px);
          margin: 0 22px 60px 22px;
        }
      }

      &::after {
        content: "";
        max-width: 365px;
        width: 100%;
        @media ${screen.xSmall} {
          max-width: 320px;
        }
        @media ${screen.small} {
          max-width: 350px;
        }
        @media ${screen.medium} {
          max-width: 330px;
        }
        @media ${screen.xLarge} {
          max-width: 380px;
        }
      }
    }

    &__video {
      background: ${lightGrey};

      video {
        max-height: auto;
        width: 100%;
        @media ${screen.medium} {
          max-height: 213px;
        }
        @media ${screen.large} {
          max-height: 241px;
        }
        @media ${screen.xLarge} {
          max-width: 380px;
          max-height: 253px;
          min-height: 253px;
        }
      }
    }

    &__texts {
      padding: 0;
      @media ${screen.large} {
        padding: 0 20px;
      }
    }

    &__title {
      color: ${darkBlue};
      font-size: 1.75rem;
      line-height: 1.2;
      margin-top: 30px;
      display: none;
      @media ${screen.medium} {
        display: block;
      }

      &--mobile {
        color: ${darkBlue};
        font-size: 1.65rem;
        line-height: 1.2;
        @media ${screen.medium} {
          display: none;
        }
      }
    }

    &__categories {
      font-weight: 300;
      color: ${darkBlue};
      font-size: 0.9rem;
      margin-top: 5px;
      display: none;
      @media ${screen.medium} {
        display: block;
      }
      &--mobile {
        font-size: 0.88rem;
        color: ${darkBlue};
        display: block;
        margin: 5px 0 15px 0;
        @media ${screen.medium} {
          display: none;
        }

        span {
          cursor: pointer;
          transition: ${transHover};
          &:hover {
            color: ${lightBlue};
          }
        }
      }
      span {
        cursor: pointer;
        &:hover {
          color: ${lightBlue};
          transition: ${transHover};
        }
      }
    }

    &__description {
      color: ${darkBlue};
      font-size: 1.1rem;
      margin-top: 14px;
      @media ${screen.medium} {
        margin-top: 20px;
      }
    }

    &__link {
      color: ${lightBlue};
      font-size: 1.25rem;
      display: none;
      margin-top: 20px;
      transition: ${transHover};
      @media ${screen.xSmall} {
        display: table;
      }
      &--mobile {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        @media ${screen.xSmall} {
          display: none;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }

    .sort-by {
      display: flex;
      align-items: center;
      max-width: max-content;
      position: relative;
      margin: 0 0 0 auto;
      @media ${screen.medium} {
        margin: 0 0 45px auto;
      }

      .icon {
        color: ${darkBlue};
        font-size: 1.15rem;
        display: block;
        position: absolute;
        right: 16px;
        top: 9px;
        @media ${screen.small} {
          font-size: 1.27rem;
          right: 15px;
        }
      }

      .select {
        all: unset;
        cursor: pointer;
        border: 1px ${darkBlue} solid;
        color: ${darkBlue};
        padding: 9px 40px 9px 21px;
        border-radius: 5px;
        font-size: 0.9rem;
        @media ${screen.small} {
          font-size: 1rem;
          padding: 9px 42px 9px 23px;
        }
      }
    }
  }
`;

const InsightsPage = ({ data }) => {
  // return array of categories to be used for filtering
  const categories = data.categories.edges
    .map((item) => item.node.data.title.text)
    .sort();

  // Toggle filter controller.
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  // default to newest
  const [sortByDate, setSortByDate] = useState("New to Old");

  // Track current selected category.
  const [selectedCategory, setSelectedCategory] = useState("All");

  // The featured blog on the top of the page.
  const latestBlog = data.content.edges[0];

  // All blog list.
  const blogs = data.content.edges;

  // Dyamic mobile height to use for toggling categories filter.
  const categoriesMobileHeight = (categories.length + 1) * 41;

  // Dyamic desktop height to use for toggling categories filter.
  const categoriesDesktopHeight = Math.round((categories.length + 1) / 6) * 61;

  // Hide or show categories list.
  const toggleCategoriesFilter = () => setFilterIsOpen(!filterIsOpen);

  // Category changer
  const selectCategory = (e) => {
    e.persist();
    setSelectedCategory(e.target.innerText);
    setFilterIsOpen(false);
  };

  // return array full list of blog - re structure it, to be used for sort by date filter
  const formattedBlogs = data.content.edges.map((blog) => {
    return {
      // return prop object as normal
      node: blog.node,
      // return a number based on string date
      dateAsNumber: blog.node.data.edit_published_date
        ? Number(blog.node.data.edit_published_date.replace(/-/g, ""))
        : Number(
            blog.node.first_publication_date.slice(0, 10).replace(/-/g, "")
          ),
    };
  });

  // return array sorted by date based on selected sortByDate string - only two option "New to Old" or "Old to New"
  const sortedByDateBlog = formattedBlogs.sort((a, b) => {
    if (sortByDate !== "New to Old") return a.dateAsNumber - b.dateAsNumber;
    return b.dateAsNumber - a.dateAsNumber;
  });

  // return filtered array of blog to be rendered, based on category selected
  const filteredBlogs =
    selectedCategory === "All"
      ? // if all is selected just return the full list
        sortedByDateBlog
      : // otherwise return the filtered array
        sortedByDateBlog.filter((blog) => {
          return blog.node.data.categories.some(
            (category) =>
              selectedCategory === category.category.document.data.title.text
          );
        });

  return (
    <Layout>
      <SEO
        title={data.seo.data.title_tag}
        description={data.seo.data.meta_description}
      />
      <Wrapper
        blogLength={blogs.length}
        categoriesMobileHeight={categoriesMobileHeight}
        categoriesDesktopHeight={categoriesDesktopHeight}
      >
        <div className="recent-blog">
          <InnerWrapper className="inner-wrapper">
            <div className="recent-blog__img">
              <Link
                to={`/insights/${toSlug(latestBlog.node.data.title.text)}`}
                aria-label={`Go to ${latestBlog.node.data.title.text} main page.`}
              >
                {latestBlog.node.data.featured_image.gatsbyImageData ? (
                  <GatsbyImage
                    image={latestBlog.node.data.featured_image.gatsbyImageData}
                    alt={latestBlog.node.data.title.text}
                  />
                ) : latestBlog.node.data.featured_video.url ? (
                  <div className="recent-blog__video">
                    <video controls>
                      <source
                        src={latestBlog.node.data.featured_video.url}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ) : (
                  <img src="x" alt="NOT FOUND" />
                )}
              </Link>
            </div>
            <div className="recent-blog__texts">
              <Link
                to={`/insights/${toSlug(latestBlog.node.data.title.text)}`}
                aria-label={`Go to ${latestBlog.node.data.title.text} main page.`}
              >
                <Subtitle className="recent-blog__title" dark>
                  {latestBlog.node.data.title.text}
                </Subtitle>
              </Link>
              <p className="recent-blog__categories">
                {latestBlog.node.data.categories.map((item, i) => (
                  <span key={i}>
                    {item.category.document &&
                      item.category.document.data.title.text}{" "}
                    /{" "}
                  </span>
                ))}
                {latestBlog.node.data.edit_published_date
                  ? format(
                      latestBlog.node.data.edit_published_date,
                      "D MMMM YYYY"
                    )
                  : format(
                      latestBlog.node.first_publication_date,
                      "D MMMM YYYY"
                    )}
              </p>
              <p className="recent-blog__description">
                {latestBlog.node.data.content.text
                  ? reduceWords(latestBlog.node.data.content.text, 29)
                  : "..."}
              </p>
              <StyledLink
                to={`/insights/${toSlug(latestBlog.node.data.title.text)}`}
              >
                READ MORE <FaChevronRight />
              </StyledLink>
            </div>
          </InnerWrapper>
        </div>

        <div className="filter-control">
          <InnerWrapper>
            <p
              className="filter-control__title"
              onClick={toggleCategoriesFilter}
            >
              Browse categories{" "}
              {filterIsOpen ? <span>&minus;</span> : <span>+</span>}
            </p>
            <ul
              className={
                filterIsOpen
                  ? "filter-control__list filter-control__list--is-open"
                  : "filter-control__list"
              }
            >
              <li
                onClick={selectCategory}
                style={{
                  boxShadow:
                    selectedCategory === "All"
                      ? `0 4px 0 -2.7px ${lightBlue}`
                      : `none`,
                  color:
                    selectedCategory === "All"
                      ? `${lightBlue}`
                      : `${mainWhite}`,
                }}
              >
                All
              </li>
              {categories.map((category, i) => (
                <li
                  onClick={selectCategory}
                  key={i}
                  style={{
                    boxShadow:
                      selectedCategory === category
                        ? `0 4px 0 -2.7px ${lightBlue}`
                        : `none`,
                    color:
                      selectedCategory === category
                        ? `${lightBlue}`
                        : `${mainWhite}`,
                  }}
                >
                  {category}
                </li>
              ))}
            </ul>
          </InnerWrapper>
        </div>
        <div className="blogs-container">
          <InnerWrapper>
            <div className="sort-by" role="button">
              <span className="icon">
                <BiSortAlt2 />
              </span>
              <select
                className="select"
                onChange={(e) => {
                  setSortByDate(e.target.value);
                }}
              >
                <option value="New to Old">Sort by: New to Old</option>
                <option value="Old to New">Sort by: Old to New</option>
              </select>
            </div>
            <main className="blogs-container__list">
              {filteredBlogs.map((blog) => {
                return (
                  <article key={blog.node.id}>
                    <div>
                      <Link
                        to={`/insights/${toSlug(blog.node.data.title.text)}`}
                      >
                        <h2 className="blogs-container__title--mobile">
                          {blog.node.data.title.text}
                        </h2>{" "}
                      </Link>
                      <p className="blogs-container__categories--mobile">
                        {blog.node.data.categories.map((item, i) => (
                          <React.Fragment key={i}>
                            <span onClick={selectCategory}>
                              {item.category.document &&
                                item.category.document.data.title.text}
                            </span>{" "}
                            /{" "}
                          </React.Fragment>
                        ))}

                        {blog.node.data.edit_published_date
                          ? format(
                              blog.node.data.edit_published_date,
                              "D MMMM YYYY"
                            )
                          : format(
                              blog.node.first_publication_date,
                              "D MMMM YYYY"
                            )}
                      </p>
                      <Link
                        to={`/insights/${toSlug(blog.node.data.title.text)}`}
                        aria-label={`Go to ${blog.node.data.title.text} main page.`}
                      >
                        <div className="blogs-container__img">
                          {blog.node.data.featured_image.thumbnails
                            .thumbnail ? (
                            <GatsbyImage
                              image={
                                blog.node.data.featured_image.thumbnails
                                  .thumbnail.gatsbyImageData
                              }
                              alt={blog.node.data.title.text}
                            />
                          ) : blog.node.data.featured_video.url ? (
                            <div className="blogs-container__video">
                              <video>
                                <source
                                  src={latestBlog.node.data.featured_video.url}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          ) : (
                            <img src="x" alt="NOT FOUND" />
                          )}
                        </div>
                      </Link>
                      <div className="blogs-container__texts">
                        <Link
                          to={`/insights/${toSlug(blog.node.data.title.text)}`}
                          aria-label={`Go to ${blog.node.data.title.text} main page.`}
                        >
                          <h2 className="blogs-container__title">
                            {blog.node.data.title.text}
                          </h2>
                        </Link>

                        <p className="blogs-container__categories">
                          {blog.node.data.categories.map((item, i) => (
                            <React.Fragment key={i}>
                              <span onClick={selectCategory}>
                                {item.category.document &&
                                  item.category.document.data.title.text}
                              </span>{" "}
                              /{" "}
                            </React.Fragment>
                          ))}
                          {blog.node.data.edit_published_date
                            ? format(
                                blog.node.data.edit_published_date,
                                "D MMMM YYYY"
                              )
                            : format(
                                blog.node.first_publication_date,
                                "D MMMM YYYY"
                              )}
                        </p>
                        <p className="blogs-container__description">
                          {blog.node.data.content.text
                            ? reduceWords(blog.node.data.content.text, 33)
                            : "..."}
                        </p>
                        <Link
                          className="blogs-container__link"
                          to={`/insights/${toSlug(blog.node.data.title.text)}`}
                          aria-label={`Read more about ${blog.node.data.title.text}.`}
                        >
                          Read more
                        </Link>
                        <StyledLink
                          className="blogs-container__link--mobile"
                          to={`/insights/${toSlug(blog.node.data.title.text)}`}
                        >
                          READ MORE <FaChevronRight />
                        </StyledLink>
                      </div>
                    </div>
                  </article>
                );
              })}
            </main>
          </InnerWrapper>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default InsightsPage;

export const data = graphql`
  {
    content: allPrismicBlog(
      sort: { fields: data___edit_published_date, order: DESC }
    ) {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            featured_image {
              alt
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              thumbnails {
                thumbnail {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            featured_video {
              url
            }
            content {
              text
            }
            edit_published_date
          }
        }
      }
    }
    categories: allPrismicCategory {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
    seo: prismicInsightsPage {
      data {
        title_tag
        meta_description
      }
    }
  }
`;
